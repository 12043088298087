@import 'Styles/variables';

.Intro {
    padding: 60px 0 40px;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 60px; }

    &-title {
        width: 30%;
        text-align: center;


        h2 {
            font-size: 42px;

            p {
                em {
                    font-style: normal;

                    &:first-of-type {
                        color: $red; }

                    &:last-of-type {
                        color: $blue; } } } } }

    &-graphicCont {
        width: 45%;
        display: flex;
        justify-content: center;

        img {
            height: 100%; } } }


@media (max-width: 1024px) {

    .Intro {
        padding: 80px 20px 0;

        .container {
            flex-direction: column;
            padding: 0;
            overflow: hidden;
            grid-gap: 40px; }


        &-title, &-graphicCont {
            width: 100%; }

        &-title {
            padding: 0 20px; }

        &-graphicCont {
            // height: 120px
            width: 100%;

            img {
                margin-left: 3%; } } } }



