h1 {
    font-size: 50px;
    line-height: 1.2;
    font-weight: 800; }

h2 {
    font-size: 42px;
    line-height: 1.3; }

h3 {
    font-size: 38px; }

h4 {
    font-size: 24px;

    &.large {
        font-size: 26px; } }

.copy {
    font-size: 20px;
    line-height: 1.2;

    &.small {
        font-size: 18px; } }




@media(max-width: 1440px) {

    h2 {
        font-size: 30px; } }




@media(max-width: 500px) {

    h1 {
        font-size: 34px; }

    h2 {
        font-size: 26px; }

    h4 {
        font-size: 20px; } }
