@import 'Styles/variables';

.categoryTag {
    background: $purple;
    color: white;
    width: max-content;
    padding: 5px 15px;
    border-radius: 30px;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 800;

    &.future-fit {
        background: $navyBlue;
        color: $babyBlue; }

    &.meaningful-work {
        background: $purple;
        color: $salmon; }

    &.step-up-step-in {
        background: $darkGreen;
        color: $lightGreen; } }





@media (max-width: 500px) {

    .categoryTag {
        font-size: 15px; } }
