@import 'Styles/variables';

.ImageCta {

    &-outer {
        padding: 100px 0;

        &.right {
            background: $navyBlue;
            color: white;

            .container {
                flex-direction: row-reverse; } }

        .container {
            display: flex;
            justify-content: center;
            gap: 60px; } }

    &-content {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        h2 {
            font-size: 42px;
            max-width: 535px;

            em {
                font-style: normal;

                &::before {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: -8px;
                    width: calc(100% + 16px);
                    height: 100%;
                    background-image: url("/Images/greenOval.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                    z-index: -1; } } }

        .copy {
            width: 90%; }

        a, button {
            width: max-content;
            margin-top: 10px;
            text-transform: uppercase;
            font-weight: bold; } }


    &-imageCont {
        width: 40%; } }






@media(max-width: 1024px) {

    .ImageCta {

        &-outer {

            .container {
                flex-direction: column; }

            &.right {
                .container {
                    flex-direction: column; } } }

        &-content {
            width: 100%;

            .copy {
                width: 100%; } }

        &-imageCont {
            width: 100%; } } }
