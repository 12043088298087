@import 'Styles/variables';

.VideoSlider {
    padding: 60px 0 150px;

    .Slider {
        .flickity-viewport {
            overflow: visible; }

        .flickity-page-dots {
            display: none; } }

    &-controls {
        width: 100%;
        height: 100%;
        position: absolute;

        > div {
            width: 100%;
            height: 100%;
            max-width: 1100px;
            margin: 0 auto; }

        .control {
            background-color: $blue;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 40%;
            display: grid;
            place-items: center;
            z-index: 4;
            margin: 0 20px;
            cursor: pointer;
            transition: 0.3s;

            i {
                color: white;
                font-size: 30px; }

            &:last-of-type {
                right: 0; }

            &:hover {
                transform: scale(1.04); } } }

    &-outer {
        h2 {
            max-width: 800px;
            text-align: center;
            margin: 0 auto;
            padding: 0 20px; } }

    &-sliderCont {
        width: 100%;
        margin: 0 auto;
        margin-top: 90px; } }





@media (max-width: 1000px) {

    .VideoSlider {
        &-controls {
            display: none; } } }





@media (max-width: 500px) {

    .VideoSlider {
        padding: 40px 0 150px;

        .Slider {
            .flickity-page-dots {
                display: revert;
                margin-bottom: -50px;

                .dot {
                    width: 15px;
                    height: 15px;
                    border: 1px solid black;
                    background: none;

                    &.is-selected {
                        background: black; } } } }


        &-sliderCont {
            margin-top: 60px; } } }
