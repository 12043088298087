@import 'Styles/variables';

.FeelGoodSection {
    padding: 80px 0;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -20px;
        width: 100%;
        height: 250px;
        background-image: url("/Images/squigLoop.png");
        background-repeat: repeat-x;
        background-size: contain; }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .copyCont {
            width: 70%;
            padding-bottom: 90px;

            h2 {
                font-size: 36px;
                text-align: center;
                width: 95%;
                max-width: 750px;
                margin: 0 auto 40px;

                &::after {
                    content: '';
                    position: absolute;
                    top: -10px;
                    right: 4%;
                    width: 30px;
                    height: 100%;
                    background-image: url("/Images/greenBits.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                    z-index: -1; }

                em {
                    font-style: normal;
                    display: inline-block;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: -15px;
                        width: calc(100% + 30px);
                        height: 110%;
                        background-image: url("/Images/blueOval.png");
                        background-size: contain;
                        background-repeat: no-repeat;
                        z-index: -1; } } }

            .copy {
                width: 95%;
                margin: 0 auto 20px;
                gap: 50px;
                text-align: center; }

            .optionsTitle {
                position: absolute;
                bottom: 0;
                left: -15%;
                border-color: black;
                border-style: solid;
                border-radius: 5px 5px 0 0;
                font-weight: 600;
                border-width: 3px 3px 0px 3px;
                padding: 10px 20px;
                height: 50px; } }

        .optionsBlock {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            border: 3px solid black;
            border-radius: 10px;


            .option {
                border-right: 3px solid black;
                padding: 60px 0;

                a {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    color: black;
                    text-decoration: none;
                    gap: 30px;

                    h4 {
                        width: 60%;
                        font-size: 20px;
                        font-weight: 800; }

                    img {
                        width: 50%; } }

                .arrow {
                    position: absolute;
                    bottom: -25px;
                    left: calc(50% - 25px);
                    width: 50px;
                    height: 50px;
                    background: black;
                    display: grid;
                    place-items: center;
                    border-radius: 50%;
                    opacity: 0;
                    transition: opacity 0.3s ease;

                    span {
                        display: none; }

                    svg {
                        width: 25px;
                        height: 25px; } }

                &:last-child {
                    border-right: none;
                    border-radius: 0 5px 5px 0; }

                &:first-child {
                    border-radius: 5px 0 0 5px; }

                &:hover {
                    .arrow {
                        opacity: 1; } } } } } }




@media(max-width: 500px) {

    .FeelGoodSection {

        &::before {
            bottom: -60px; }

        .container {
            padding: 0;

            .copyCont {
                width: 100%;
                padding: 0 20px 80px;


                h2, .copy {
                    width: 100%; }

                h2 {
                    text-align: left;
                    font-size: 34px;
                    margin-bottom: 30px;

                    &::after {
                        display: none; } }

                .copy {
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    gap: 30px;
                    text-align: left; }

                .optionsTitle {
                    border: none;
                    left: 0;
                    bottom: -10px; } }

            .optionsBlock {
                border: none;
                display: grid;
                grid-templacte-columns: repeat( 2, 1fr );
                width: 100%;
                padding: 20px;
                padding-left: 20px;
                grid-auto-flow: column;
                grid-auto-columns: 45%;
                gap: 15px;
                overflow-y: hidden;
                overflow-x: auto;
                overscroll-behavior-inline: contain;
                grid-template-columns: inherit;
                scroll-snap-type: inline mandatory;
                scroll-padding-inline: 20px;

                .option {
                    border: 3px solid black;
                    min-width: auto;
                    width: 100%;
                    padding: 20px 20px 50px;
                    scroll-snap-align: start;
                    border-radius: 20px;

                    .arrow {
                        opacity: 1;
                        width: 130px;
                        border-radius: 10px;
                        display: flex;
                        gap: 5px;
                        padding: 0 15px;
                        left: calc(50% - 65px);
                        height: 40px;
                        bottom: -20px;

                        span {
                            color: white;
                            display: inline;
                            font-size: 15px; }

                        svg {
                            width: 10px;
                            height: 10px; } }

                    &:last-child {
                        border-right: 3px solid black;
                        border-radius: 20px; }

                    &:first-child {
                        border-radius: 20px; }

                    a {
                        img {
                            width: 60%; }

                        h4 {
                            width: 100%; } } } } } } }

