
$red: #D90432;

$blue: #1378D1;
$darkBlue: #032264;
$babyBlue: #D2EDF4;
$brightBlue: #CEF8FC;
$navyBlue: #222A60;
$anotherBlue: #14415C;

$salmon: #FDE6E3;
$purple: #771C55;
$lightPurple: #8A59AD;
$darkPurple: #761554;

$green: #7FC99C;
$lightGreen: #D9ECD0;
$darkGreen: #084344;
$textGreen: #164344;
$forestGreen: #054243;

$yellow: #F9CE78;

$darkGrey: #2E2E2E;
$brown: #7A3F1B;
