@import 'Styles/variables';

.Hero {
    .outer {
        padding: 150px 0 0;
        height: 100%;
        max-width: 100%;
        transition: 0.3s;

        .bgCont {
            position: absolute;
            width: 100%;
            height: 75%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;

            .bg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: 0.3s;

                &.active {
                    opacity: 1; } } }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 25%;
            background: white; }

        &.health-and-wellbeing {
            background: $lightPurple;

            .tab.selected {
                h4 {
                    color: $lightPurple; } } }


        &.learning-and-growing {
            background: $blue;

            .tab.selected {
                h4 {
                    color: $blue; } } }


        &.meaningful-work {
            background: $anotherBlue;

            .tab.selected {
                h4 {
                    color: $anotherBlue; } } }

        h1 {
            text-align: center;
            font-weight: 800;
            color: white; }

        .heroCopy {
            width: 70%;
            max-width: 1100px;
            text-align: center;
            margin: 30px auto 10px;
            color: white;

            p {
                line-height: 34px;
                font-size: 22px;

                em {
                    font-style: normal;
                    font-weight: 600; } } }

        .searchBox {
            max-width: 1200px;
            margin: 0 auto;
            margin-top: 50px;
            z-index: 1;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border-radius: 0 0 30px 30px;

            &-tabs {
                display: flex;
                grid-gap: 20px;
                bottom: -10px;

                .tab {
                    height: 130px;
                    width: calc(100% / 3);
                    border-radius: 10px 10px 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: white;
                    transition: background 0.3s;
                    padding-bottom: 10px;
                    cursor: pointer;
                    opacity: 0.7;

                    h4 {
                        font-size: {}
                        color: black;
                        opacity: 0.5; }

                    svg {
                        height: 70%;

                        path {
                            fill: grey; } }

                    &.selected {
                        opacity: 1;
                        h4 {
                            opacity: 1; }
                        svg {
                            path {
                                fill: white; } } } } }

            &-button {
                cursor: pointer;
                position: absolute;
                top: -50px;
                right: 70px;
                z-index: 1;
                transition: 0.3s;

                &:hover {
                    transform: scale(1.1); }

                svg {
                    display: block;
                    width: 180px;

                    #showAll-outer {
                        transform-origin: center;
                        animation: rotate-360 10s linear infinite; }

                    @keyframes rotate-360 {
                        from {
                            transform: rotate(0deg); }
                        to {
                            transform: rotate(360deg); } }

                    #showAll-inner {
                        transform-origin: center;
                        transform: rotate(37deg); } }

                &-logoInner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: grid;
                    place-items: center;

                    > svg {
                        width: 35%;

                        path {
                            fill: #F5EAD1; } } }

                &-mobile {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 15px 10px;
                    background-color: $red;
                    color: #f5ead1;
                    font-weight: bold;
                    cursor: pointer;
                    border-radius: 10px;
                    margin: 15px 0 0;
                    display: none;

                    svg {
                        display: block;
                        height: 35px;

                        &:last-child {
                            height: 22px; } } } }

            &-bottom {
                transition: background 0.3s;
                padding: 30px;
                border-radius: 10px;
                color: black;
                background: white;

                &-heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 20px;

                    h4 {
                        padding: 0; }

                    .button {
                        flex-shrink: 0; } }

                &-inputs {
                    background: #FCFCFC;
                    overflow: hidden;
                    border-radius: 10px;
                    border: 1px solid transparentize($darkGrey, 0.8);

                    .searchInput {
                        width: 100%;
                        border-bottom: 1px solid transparentize($darkGrey, 0.8);

                        input {
                            width: 100%;
                            border: none;
                            padding: 20px;
                            font-size: 24px;
                            background: #FCFCFC; }

                        i {
                            position: absolute;
                            right: 20px;
                            top: 20px;
                            font-size: 28px;
                            color: grey;

                            &.fe-x {
                                cursor: pointer; } } }

                    .suggested {
                        min-height: 75px;
                        padding: 20px;
                        color: transparentize($darkGrey, 0.4);
                        display: flex;
                        align-items: center;

                        > span {
                            display: block;
                            margin: 0 20px 0 0; }

                        ul {
                            display: flex;
                            list-style: none;
                            margin: 0;
                            padding: 0;

                            li {
                                display: flex;
                                text-align: center;
                                margin: 0 10px 0 0;
                                padding: 5px 16px;
                                border-radius: 20px;
                                text-transform: uppercase;
                                border: 1px solid transparentize($darkGrey, 0.5);
                                cursor: pointer;
                                transition: background 0.3s;

                                &:hover {
                                    background: transparentize($darkGrey, 0.95); }

                                &:last-child {
                                    margin: 0; }

                                span {
                                    font-size: 16px; } } } } } } } } }





@media (max-width: 1024px) {

    .Hero {
        .outer {
            .searchBox {
                &-button {
                    display: none;

                    &-mobile {
                        display: flex; } } } } } }




@media (max-width: 500px) {

    .Hero {
        height: auto;

        .outer {
            height: auto;
            padding-top: 110px;
            padding-bottom: 60px;

            h1 {
                margin: 0 20px; }

            .heroCopy {
                width: 100%;
                padding: 0 20px; }

            &::after {
                display: none; }

            .searchBox {
                width: 100%;
                padding-top: 30px;

                &-tabs {
                    grid-gap: 10px;

                    .tab {
                        height: 90px;

                        h4 {
                            font-size: 16px;
                            text-align: center; }

                        svg {
                            height: 55%; } } }

                &-bottom {
                    padding: 20px;

                    &-heading {
                        align-items: flex-start;

                        h4 {
                            margin: 0; } }

                    &-inputs {
                        .searchInput {
                            input {
                                font-size: 16px; }

                            i {
                                top: 17px;
                                font-size: 22px; } }

                        .suggested {
                            flex-direction: column;
                            align-items: center;
                            padding: 16px 10px 10px;
                            grid-gap: 10px;

                            > span {
                                font-size: 16px;
                                margin: 0; }

                            ul {
                                flex-wrap: wrap;
                                justify-content: center;
                                grid-gap: 0;
                                color: transparentize($darkGrey, 0.2);

                                li {
                                    margin: 5px;
                                    padding: 10px 16px;
                                    font-weight: bold;
                                    border: 1px solid transparentize($darkGrey, 0.6);

                                    &:last-child {
                                        margin: 5px; }

                                    span {
                                        font-size: 12px; } } } } } } } } } }
