@import 'Styles/variables';

.QuizCta {
    padding: 70px 0 0;
    background: $yellow;
    overflow-x: clip;

    .container {
        display: flex;
        grid-gap: 60px;
        align-items: center;
        justify-content: flex-start;

        svg {
            position: absolute;

            path {
                fill: $darkGrey; }

            &:first-of-type {
                width: 15%;
                top: -120px;
                left: 25%; }

            &:last-of-type {
                width: 30%;
                right: -110px;
                top: -30px; } } }

    &-copy {
        width: 40%;
        display: flex;
        flex-direction: column;
        grid-gap: 20px;

        h2 {
            font-weight: 800; }

        .button {
            width: max-content; } }

    &-imageCont {
        width: 60%;

        img {
            width: 90%;
            bottom: -30px;
            z-index: 1; } } }


@media (max-width: 500px) {

    .QuizCta {

        .container {
            flex-direction: column;
            grid-gap: 0;

            svg {
                &:first-of-type {
                    display: none; }

                &:last-of-type {
                    right: -70px;
                    top: 40%;
                    width: 45%; } } }

        &-copy, &-imageCont {
            width: 100%; }

        &-imageCont {

            img {
                width: 100%; } }

        &-copy {
            text-align: center;
            align-items: center;
            grid-gap: 20px;

            .button {
                width: 100%; } } } }

