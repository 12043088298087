@import 'Styles/variables';

.SearchResults {
    padding: 50px 0 100px;

    .container {
        opacity: 0;
        transform: translateY(-15px);
        visibility: hidden;
        transition: 0.3s;

        &.active {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } }

    &-top {
        max-width: 1230px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;

        i {
            color: black;
            font-size: 35px;
            font-weight: bold;
            cursor: pointer; } }

    &-resultsGrid {
        max-width: 1230px;
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: center;
        grid-gap: 30px 20px;
        padding: 0;

        li {
            height: 100%;
            list-style: none;
            border: 2px solid black;
            border-radius: 15px;
            padding: 0 30px 30px;
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
            cursor: pointer;

            .categoryTagCont {
                width: 100%;
                position: absolute;
                top: -17px;
                left: 0;
                display: flex;
                justify-content: center;

                span {
                    margin: 0 auto; } }

            &.program {
                border-color: $yellow;
                padding-top: 90px;

                .categoryTagCont {
                    display: none; } }

            &:hover {
                .openModal {
                    transform: scale(1.04); } }

            .openModal {
                border: 2px solid black;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                position: absolute;
                bottom: 30px;
                right: 30px;
                display: grid;
                place-items: center;
                z-index: 4;
                transition: 0.3s;

                i {
                    font-size: 30px; } }

            .programBanner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background: $yellow;
                display: flex;
                justify-content: center;
                padding: 12px;

                span {
                    font-weight: 800;
                    text-transform: uppercase; } }

            .iconCont {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 120px;
                margin-top: 50px;

                svg {
                    height: 100%; } }

            .copyCont {
                display: flex;
                flex-direction: column;
                grid-gap: 10px;
                margin: 0 0 60px;

                .copy {
                    width: 90%;
                    font-size: 16px; } }

            .titleCont {
                display: flex;
                align-items: flex-start;
                margin-top: 10px;

                h4 {
                    font-weight: 800;
                    font-size: 24px; } } } }

    .viewButtons {
        display: flex;
        align-items: center;

        span {
            font-size: 18px;
            font-weight: 800;
            opacity: 0.7;
            margin: 0 10px 0 0; }

        .button {
            margin: 0 10px 0 0;
            background-color: transparent;
            color: $darkGrey;
            border: 1px solid $darkGrey;
            opacity: 0.6;

            &:last-child {
                margin: 0; }

            &.active {
                background-color: $blue;
                color: white;
                border-color: $blue;
                opacity: 1; } } } }





@media (max-width: 1200px) {

    .SearchResults {
        &-resultsGrid {
            grid-template-columns: repeat(2, 1fr); } } }





@media (max-width: 760px) {

    .SearchResults {
        &-top {
            flex-direction: column; }

        &-resultsGrid {
            grid-template-columns: repeat(1, 1fr); } } }





@media (max-width: 500px) {

    .SearchResults {
        &-top {
            flex-direction: column; }

        &-resultsGrid {
            li {
                height: auto;
                padding: 0px 20px 30px;
                gap: 5px;

                > span {
                    top: -14px; }

                .programBanner {
                    position: relative;
                    width: calc( 100% + 60px );
                    margin: -30px 0 0 -30px; }

                .iconCont {
                    height: 70px; }

                .copyCont {
                    width: 85%; }

                .openModal {
                    width: 40px;
                    height: 40px;
                    right: 20px;

                    i {
                        font-size: 24px; } } } }

        .viewButtons {
            margin: 20px 0 0; } } }
