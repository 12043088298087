@import 'Styles/variables';

.slide {
    width: 700px;
    height: 400px;
    z-index: 1;

    &.is-selected {
        z-index: 99;

        .slide-inner {
            opacity: 1;
            transform: scale(1.2); } }

    .buttonCont {
        position: absolute;
        bottom: 20px;
        right: 20px; }

    .button {
        background-color: $yellow;

        a {
            color: $darkGrey;
            text-decoration: none; }

        i {
            margin: 0 0 0 10px; } }

    &-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        padding: 50px;
        border-radius: 6px;
        opacity: 0.4;
        overflow: hidden;
        transform: scale(1);
        transition: 0.3s;
        color: white;

        &.health-and-wellbeing {
            background-color: $lightPurple; }

        &.learning-and-growing {
            background-color: $blue; }

        &.meaningful-work {
            background-color: $anotherBlue; } }

    &-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.4;
        background-position: top right;
        background-size: contain;
        background-repeat: no-repeat; }

    &-main {
        max-width: 500px;

        h1 {
            margin: 0 0 10px; } } }



@media (max-width: 500px) {

    .slide {
        width: 70%;
        height: 300px;

        &.is-selected {
            .slide-inner {
                transform: scale(1.25); } }

        &-inner {
            padding: 30px; }

        &-main {
            margin-top: -50px;

            h1 {
                font-size: 26px;
                line-height: 1.1; } } } }
