@import 'Styles/variables';

.BenefitSlider {
    padding: 50px 0;

    &-cont {
        display: flex;
        justify-content: center;
        align-items: center; }

    &-title {
        display: none;
        padding: 30px;
        color: $red;

        h1 {
            writing-mode: vertical-rl;
            transform: rotate(-180deg);
            white-space: nowrap;
            font-size: 80px; } }

    &-types {
        display: flex; }

    &-loading {
        margin: 60px 0 0; }

    &-type {
        width: 25%;
        min-height: 700px;
        flex-shrink: 0;
        padding: 60px 30px 260px;
        overflow: hidden;
        color: white;

        svg {
            display: block;
            width: 60%;
            height: auto;
            position: absolute;
            bottom: 3%;
            left: -3%;
            fill: white;
            opacity: 0.6; }

        h2 {
            margin: 0 0 30px; }

        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            list-style: none;
            margin: 60px 0 0;
            padding: 0;
            font-weight: bold;

            li {
                display: flex;
                align-items: flex-start;
                cursor: pointer;
                padding: 4px 0;
                font-size: 18px;

                i {
                    margin: 2px 0 0;
                    padding-right: 5px;
                    opacity: 0.7;
                    transition: 0.3s; }

                > div {
                    span {
                        &:first-child {
                            opacity: 0.7;
                            transition: 0.3s; }

                        &.newOrUpdatedTag {
                            display: inline-flex; } } }

                &:hover {
                    > div span, i {
                        opacity: 1; } } } }

        &-you-and-your-family {
            background-color: $anotherBlue; }

        &-community-and-connection {
            background-color: $darkPurple;

            svg {
                bottom: 10%;
                left: 20%;
                opacity: 0.4; } }

        &-health-and-wellbeing {
            background-color: $darkBlue;

            svg {
                width: 50%;
                bottom: 6%;
                left: 30%;
                opacity: 0.4; } }

        &-personal-development {
            background-color: $forestGreen;

            svg {
                width: 30%;
                bottom: 4%;
                left: 35%;
                opacity: 0.4; } } } }





@media (max-width: 500px) {

    .BenefitSlider {
        &-cont {
            flex-direction: column; }

        &-title {
            display: revert;
            width: 100%;
            flex-shrink: 0;
            padding: 5px;
            text-align: center;
            margin: 0 0 5px;

            h1 {
                writing-mode: unset;
                transform: rotate(0);
                font-size: 40px; } }

        &-types {
            width: 100%;
            overflow: auto;
            justify-content: flex-start;

            overscroll-behavior-inline: contain;
            grid-template-columns: inherit;
            scroll-snap-type: inline mandatory;
            scroll-padding-inline: 20px; }

        &-type {
            width: calc( 100% - 40px );
            min-height: auto;
            margin: 0 5px;
            padding: 30px 30px 160px;
            scroll-snap-align: start;
            border-radius: 15px;

            h2 {
                margin: 0 0 15px; }

            ul {
                margin: 30px 0 0;
                font-size: 20px; }

            svg {
                left: -10%; }

            &-family {
                margin-left: 20px; }

            &-community {
                svg {
                    width: 75%;
                    bottom: 5%;
                    left: auto;
                    right: -5%; } }

            &-health-and-fitness {
                svg {
                    bottom: 2%;
                    left: auto;
                    right: -5%; } }

            &-personal-development {
                margin-right: 20px;

                svg {
                    width: 40%;
                    bottom: 0%;
                    left: auto;
                    right: 2%; } } } } }
