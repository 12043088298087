@import 'Styles/variables';

.header {
    display: block;
    position: fixed;
    top: 10px;
    width: 100%;
    z-index: 12;
    padding: 0 10px;

    .container {
        display: flex;
        justify-content: space-between;
        padding: 15px 30px;
        background: rgba(white, 0.95);
        backdrop-filter: blur(4px);
        box-shadow: 0 0 10px rgba(black, 0.1);
        border-radius: 15px;
        margin: 0 auto;
        width: 100%;

        svg {
            display: block;
            width: 160px;
            height: 40px; }

        .headerMenu {
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            margin: 0 0 0 auto;

            li {
                a {
                    color: $darkGrey;
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 16px;
                    letter-spacing: 1px;
                    padding: 10px 20px;
                    display: block;
                    opacity: 0.6;
                    transition: 0.3s;

                    &:hover {
                        opacity: 1; } } } }

        .button {
            font-weight: bold;
            text-decoration: none;
            text-transform: uppercase;
            background-color: $blue;
            border: 1px solid $blue;
            color: white;

            &:hover {
                background-color: $babyBlue;
                color: $blue; } }

        .toggle {
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 10px;
            right: 20px;

            > span, &::before,&::after {
                content: '';
                display: block;
                width: 70%;
                height: 5px;
                background-color: $red;
                margin: 2px 0;
                border-radius: 3px;
                transform-origin: top;
                transition: 0.3s; }

            &::before,&::after {
                transition: 0.5s; }

            &::after {
                transform-origin: bottom; } } } }



@media(max-width: 1024px) {

    .header {
        &.open {
            .container {
                flex-direction: column;
                background: white;

                .headerMenu, .button {
                    display: revert; }

                .headerMenu {
                    margin: 50px 0 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    grid-gap: 20px;

                    li > a {
                        font-size: 28px; } }

                .button {
                    margin-bottom: 30px;
                    text-align: center;
                    font-size: 20px; }

                .toggle {
                    > span {
                        opacity: 0; }

                    &::before {
                        transform: rotate(-45deg) translateY(6px) translateX(-9px); }

                    &::after {
                        transform: rotate(45deg) translateY(-5px) translateX(-7px); } } } }

        .container {

            .toggle {
                display: flex; }

            .headerMenu, .button {
                display: none; } } } }
