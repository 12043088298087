@import 'variables';
@import 'typography';

html, body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    overscroll-behavior-x: contain; }

body {
    font-family: 'museo-sans-rounded', sans-serif;
    font-size: 23px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    outline: none;
    color: $darkGrey; }

*, *:before, *:after {
    position: relative;
    outline: none;
    box-sizing: border-box;
    margin: 0; }

img, picture, video, canvas, svg {
    display: block;
    max-width: 100%; }

input, button, textarea, select {
    font: inherit; }

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word; }


.flexible-content {
    overflow: hidden;

    &.searchResultsVisible {
        > div {
            display: none;

            &.VideoSlider {
                display: block; } } } }

.container {
    width: 100%;
    max-width: 1600px;
    margin: auto;
    padding: 0 30px; }

.mainloading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    svg {
        display: block;
        width: 100px;
        height: 100px; } }

.newOrUpdatedTag {
    display: block;
    font-size: 15px;
    color: $darkGrey;
    font-weight: 800;
    text-transform: uppercase;
    background-color: $yellow;
    padding: 3px 6px;
    margin: 0 0 0 10px;
    border-radius: 4px;

    &.new {
        background-color: pink; }

    &.updated {
        background-color: mistyrose; } }




@media(max-width: 500px) {

    .container {
        padding: 0px 20px; } }
