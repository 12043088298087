@import 'Styles/variables';

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0,0,0,0.7);
    transition: 0.3s;
    transform: scale(1.05);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &.visible {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: scale(1); }

    &>div {
        width: 100%;
        background: white;
        box-shadow: 0 5px 30px rgba(0,0,0,0.2);
        overflow: hidden;
        max-width: 800px;
        background-color: white;
        border-radius: 10px;
        height: 70vh;
        max-height: 550px;
        overflow: hidden;

        .close {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            border: none;
            appearance: none;
            background: rgba(0,0,0,0);
            font-size: 28px;
            color: white;
            padding: 16px;
            transition: 0.1s;
            cursor: pointer;
            outline: none;

            &:hover {
                opacity: 0.8; } } }

    &.BENEFIT {
        .BENEFIT {
            border: 2px solid black; }

        .benefitInner {
            display: flex;
            height: 100%;
            width: 100%;

            .closeModal {
                position: absolute;
                right: 30px;
                top: 20px;
                cursor: pointer;
                z-index: 2;

                i {
                    font-size: 30px;
                    font-weight: bold; } }

            .iconCont {
                width: 35%;
                border-right: 2px solid black;
                display: grid;
                place-items: center;

                svg {
                    width: 70%; }

                &.health-and-wellbeing {
                    background: $navyBlue;

                    svg {
                        path {
                            fill: $babyBlue; } } }

                &.learning-and-growing {
                    background: $purple;

                    svg {
                        path {
                            fill: $salmon; } } }

                &.meaningful-work {
                    background: $darkGreen;

                    svg {
                        path {
                            fill: $lightGreen; } } }

                &.program {
                    background: $yellow;

                    svg {
                        path {
                            fill: $brown; } } } }

            .benefitContent {
                width: 65%;
                padding: 30px;
                display: flex;
                flex-direction: column;
                grid-gap: 15px;

                .benefitCopy {
                    overflow-y: auto;

                    a {
                        color: $blue;
                        text-decoration: underline;
                        font-weight: bold;

                        &:hover {
                            text-decoration: none; } } }

                .titleCont {
                    display: flex;
                    align-items: flex-start; }

                h4 {
                    font-weight: 800; }

                .button {
                    width: max-content;

                    a {
                        color: white;
                        text-decoration: none; } } } } } }






@media(max-width: 500px) {

    .Modal {
        padding: 0 20px;

        &.BENEFIT {
            > div {
                height: 100%;
                max-height: 85vh; }

            .benefitInner {
                flex-direction: column;

                .closeModal {
                    right: 20px;

                    i {
                        color: white; } }

                &.programInner {
                    .closeModal {
                        i {
                            color: $brown; } } }

                .iconCont {
                    width: 100%;
                    height: 35%;
                    border-right: none;
                    border-bottom: 2px solid black;

                    svg {
                        width: auto;
                        height: 70%; } }

                .benefitContent {
                    width: 100%;
                    overflow-y: scroll;
                    height: 65%; } } } } }

