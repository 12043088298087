@import 'Styles/variables';

.button {
    background: $blue;
    padding: 10px 16px;
    border-radius: 5px;
    font-size: 18px;
    border: none;
    color: white;
    appearance: none;
    cursor: pointer;
    transition: 0.3s;

    &.disabled {
        opacity: 0.5;
        cursor: initial; }

    &:hover {
        background: darken($blue, 5%); }

    a {
        text-decoration: none;
        color: white; } }

